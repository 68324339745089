.component{
    position: relative;
    width: 100%;
    /* margin-top: auto; */
}

.time {
    font-size: 11px;
    width: 100%;
    height: 12px;
    opacity:0.7;
    position: relative;
    top: -6px;
    pointer-events: none;
    user-select: none;
    touch-action: none;
}

.currentTime{
    position: absolute;
    left: 0;
}

.duration{
    position: absolute;
    right: 0;
}

.track {
    position: relative;
    height: 26px;
    padding: 6px 0px;
    cursor: pointer;
    margin-top: -4px;
    box-sizing: border-box;
}

.fakeTrack{
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 6px;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 3;
    pointer-events: none;
    border-radius: 4px;
    height: 4px;
}

.loaded {
    position: absolute;
    border-radius: 4px;
    bottom: 6px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.2);
    pointer-events: none;
    height: 4px;
    z-index: 2;
    transition: 1s all;
}

.played {
    position: absolute;
    border-radius: 4px;
    bottom: 6px;
    left: 0px;
    background-color: rgb(238, 48, 0);
    height: 4px;
    z-index: 3;
    pointer-events: none;
    transition: .5s all;
}

.thumb{
    z-index: 12;
    position: absolute;
    transform: translateX(-50%);
    bottom: 2px;
    opacity: 0;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background-color:rgb(238, 48, 0);
    transition: all .5s;
    pointer-events: none;
    user-select: none;
    touch-action: none;
}

.hoverTime {
    text-align: center;
    position: absolute;
    z-index: 20;
    margin-top: 8px;
    top: 0px;
    padding: 3px 9px 5px;
    font-size: 11px;
    background-color: rgb(26, 26, 26);
    border-radius: 4px;
    color: rgba(255, 255, 255);
    transform: translate(-50%, -100%);
    box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    user-select: none;
}

.component:hover .thumb {
    opacity: 1;
}

.component:hover .hoverTime {
    opacity: 1;
}