.frame{
    min-height: 15px;
    max-height: 360px;
    width: 100%;
    overflow: hidden;
    position: relative;
    user-select: none;
}
.container{
    overflow: scroll;
    max-height: 375px;
    padding-right: 0px;
    padding-bottom: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
}

.fakeScrollBar{
    position: absolute;
    right: 2px;
    top:2px;
    bottom: 2px;
    width: 6px;
}

/* .fakeScrollBar_thumb{
    opacity: 1;
    transition: background-color 0.3s ease 0s;
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
    width: 8px;
    height: 60px;
    border-radius: 4px;
    cursor: pointer;
} */