.playlistItem{
    display: flex;
    width: 100%;
    padding: 6px;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    transition: all 0.2s;
}
.playlistItem:last-child{
   margin-bottom: 16px;
}

.playlistItem:hover,
.playlistItem:focus
{
    background-color: rgba(0, 0, 0, 0.08);
    outline: none;
}

.img {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
}

.img img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.playlistItem .img .iconContainer {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
    top: 0;
    left: 0;
    transition: all 0.5s;
}

.playlistItem:hover .img .iconContainer {
    opacity: 1;
}

.playlistItem .img .iconContainer svg{
    /* opacity: 0; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    transition: all 0.5s;
}

.playlistItem:hover .img .iconContainer svg {
    opacity: 1;
}

.info{
    margin-left: 18px;
}

.track {
    font-size: 14px;
    line-height: 1.2;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
}

.artist {
    font-size: 13px;
    line-height: 1.2;
    margin-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.7;
    margin-right: 8px;
}

.duration{
    margin-left: auto;
    font-size: 13px;
    line-height: 1.2;
    text-align: right;
    padding-left: 0px;
    opacity: 0.7;
}