.playerControls {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    margin-top: 12px;
    line-height: 0;
}

.defaultButton{
    margin-left: 20px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.defaultButton:first-child{
    margin-left: 0px;
}

.playButton{
    width: 28px;
    height: 28px;
    flex-basis: 28px;
}

.shuffleButton{
    margin-left: auto;
}

.volumeControlProvider {
    position: relative;
}

.volumeControlProvider:hover .volumeControl {
    pointer-events: all;
    opacity: 1;
}

.volumeControl {
    cursor: auto;
    pointer-events: none;
    opacity: 0;
    z-index: 5;
    left: -5px;
    top: auto;
    bottom: 100%;
    position: absolute;
    transition: .2s all;
}

.volumeControlInner {
    padding: 15px 0;
    margin: 10px 0;
    box-shadow: 0 0 10px #0000005c;
    background-color: #fff;
    border-radius: 8px;
    height: 120px;
    width: 28px;
}

.volumeSlider {
    cursor: pointer;
    margin: 0 auto;
    width: 6px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 13;
    pointer-events: all;
    border-radius: 4px;
    height: 100%;
    position: relative;
}

.volumeLevel {
    position: absolute;
    bottom: 0;
    left: 0px;
    border-radius: 4px;
    background-color: rgb(238, 48, 0);
    width: 6px;
    z-index: 3;
    pointer-events: none;
    /* transition: .2s all; */
}

.volumeThumb{
    position: absolute;
    cursor: pointer;
    transform: translateY(50%);
    bottom: 0;
    left: -5px;
    z-index: 12;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    background-color:rgb(238, 48, 0);
    /* transition: all .2s; */
    pointer-events: all;
    user-select: none;
    touch-action: none;
}