.player-container {
    display: flex;
    margin: 0 auto;
    padding: 0 6px;   
    padding-bottom: 20px;
}

.album-art{
    flex-shrink: 0;
    width: 180px;
    height: 180px;
    background-color: rgb(189, 189, 189);
    border-radius: 8px;
    position: relative;
}

.album-art img {
    width: 100%;
    border-radius: 8px;
}

.player-full{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-left: 20px;
}
.player-trackname{
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.player-artistname{
    font-size: 13px;
    line-height: 1.2;
    margin-top: 2px;
    opacity: 0.7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 600px) {

    .player-full {
        margin: 10px 0;
    }

    .player-container {
        flex-direction: column;
        align-items: center;
    }

    .player-trackname {
        text-align: center;
        font-size: 20px;
    }

    .player-artistname {
        text-align: center;
        font-size: 14px;
    }
}